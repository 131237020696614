import $ from 'jquery';
import Swiper, { Pagination } from "swiper";

const windowWidth = window.innerWidth;
const allCarouselsHome = document.querySelectorAll(".js-columns-carousel.home");
let index;

if ($('.bottle').hasClass('highlighted')) {
  index = $('.swiper-wrapper .highlighted').index();
} else {
  index = Math.floor($('.bottle').length/2);
}

const linksTimeOut = () => {
  $('.bottle').on('click', function(){
    if(!$(this).hasClass('highlighted')) {
      $('.bottle').removeClass('highlighted').removeClass('no-active');
      $(this).addClass('highlighted');
    }
  });

  $('.bottle_link').on('click', function(e){
    e.preventDefault();
    setTimeout(() => {
      window.location = $(this).attr('href');
    }, 2000)
  });
}

if (allCarouselsHome) {
  let slidersHome = [];

  const sliderInitHome = function () {

    allCarouselsHome.forEach((item) => {

      let sliderHome = new Swiper(item, {
        slidesPerView: 2.5,
        spaceBetween: 0,
        centeredSlides: true,
        slideToClickedSlide: true,
        initialSlide: index,
        preventClicks: false,
        preventClicksPropagation: false,
        preventInteractionOnTransition: true,
        setWrapperSize: true,
        breakpoints: {
          500: {
            slidesPerView: 3,
          },
          767: {
            slidesPerView: 'auto',
          },
          991: {
            slidesPerView: 'auto',
          },
        },
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd: function() {
            $('.bottle').removeClass('highlighted');
            $('.swiper-slide-active').addClass('highlighted');
          },
          touchEnd: function() {
            $('.bottle').removeClass('no-active');
          },
        },
      });
      slidersHome.push(sliderHome);
    });
  };

  const sliderDestroyHome = function () {
    if (slidersHome.length) {
      for (let i = 0; i < slidersHome.length; i++) {
        slidersHome[i].destroy();
      }
      slidersHome = [];
    }
  };

  window.addEventListener("load", () => {
    linksTimeOut();

    if (window.innerWidth <= 1399) {
      sliderInitHome()
    } else {
      sliderDestroyHome()
    }
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth != windowWidth && window.innerWidth <= 1399) {
      sliderInitHome()
    } else if (window.innerWidth != windowWidth && window.innerWidth >= 1400) {
      sliderDestroyHome()
    }
  });
}



